import * as Clickable from "components/clickable"
import { Typography } from "components/typography"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { connect, useStore } from "react-redux"
import { apis as API } from "@sog/sdk"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import { addCard, updateOtherBanking } from "state/actions"
import SGTextfield from "../../textfield"
import CardUsageViewController from "../card-usage-view-controller"
import ProductsListView from "../products-list-view"
import ProgressBar from "../progress-bar"
import ProvidersListView from "../providers-list-view"
import RewardProgramsListView from "../reward-programs-list-view"
import * as styles from "./style.module.scss"

export const AddCard = props => {
  const store = useStore()
  const [cardProvider, setCardProvider] = useState(props.location.state.cardProvider ? props.location.state.cardProvider : null)
  // const [selectedProviders, setSelectedProviders] = useState(null)
  const [rewardProgram, setRewardProgram] = useState(props.location.state.rewardProgram ? props.location.state.rewardProgram : null)
  const [product, setProduct] = useState(props.location.state.product ? props.location.state.product : null)
  const [addMissingCard] = useState(props.location.state.addMissingCard)
  // const [unknownProviderName, setUnknownProviderName] = useState("")
  const activeStep = props.location.state.activeStep || 0

  const rewardPrograms = props.rewardPrograms || []
  const providers = props.providers || []
  const products = props.products || []

  const NOT_HERE_ID = 0
  // const cardNotHere = {
  //   id: NOT_HERE_ID,
  //   cardProvider: cardProvider,
  //   rewardProgram: rewardProgram,
  //   name: "My card is not here",
  //   scheme: "",
  // }
  const progress = props.location.state.progress || 0

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [providerName, setProviderName] = useState(props.location.state.providerName ? props.location.state.providerName : "")
  const [productName, setProductName] = useState(props.location.state.productName ? props.location.state.productName : "")

  function addNewCard(newCard, selectedProviders) {
    // //console.log("newCard:", newCard);
    props.dispatch(addCard(newCard))
    // props.history.replace("/credit-cards/my-cards");
    //console.log("selectedProviders:", selectedProviders)
    props.dispatch(updateOtherBanking({ mainBank: 0, homeOwnership: 0, selectedProviders }))

    const jsonInput = {
      surveyId: props.surveyId,
      productId: newCard.product.id,
      productName: newCard.product.name,
      schemeId: newCard.product.scheme,
      monthlySpend: newCard.usage.monthlySpend,
      balanceRemaining: newCard.usage.balanceRemaining,
      monthlyRepayment: newCard.usage.monthlyRepayment,
    }
    //console.log('jsonInput2222:', jsonInput)
    // const temp = {
    //   balanceRemaining: 2,
    //   monthlyRepayment: 2,
    //   monthlySpend: 2,
    //   productId: 20010100,
    //   session: "1687f666-56a8-45c3-aaf9-4717780dc349",
    //   surveyId: "a687c051-66f0-4388-8be6-2509e216f3e8",
    // }
    API.myCurrentDeal(jsonInput, responseJson => {
      navigate("/credit-cards/your-current-deal", {
        state: {
          responseJson,
          jsonInput,
          progress: progress + 1,
          prevPath: props.path,
        },
      })
    })
  }

  function isUnknownCard() {
    return product !== null && product.id === NOT_HERE_ID
  }

  // const submitUnknownCardAction = () => {
  //
  // }

  const onProductName = e => {
    setProductName(e.target.value)
  }

  const rewardsListForProvider = provider => {
    return Array.from(
      new Set(
        providers
          .find(x => x.id === provider.id)
          .products.filter(x => x.inSurvey === true)
          .map(item => {
            return rewardPrograms.find(x => x.id === item.rewardProgram)
          }),
      ),
    ).sort((a, b) => (a.id > b.id ? 1 : -1))
  }

  const didSelectCardProvider = value => {
    DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_PROVIDER_SELECTED, { id: value.id, productCategory: "CREDIT_CARD", ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })
    setCardProvider(cardProvider === null ? value : null)
    setRewardProgram(null)
    setProduct(null)

    if (value.id !== 0) {
      const rewardProgramsList = rewardsListForProvider(value)
      const showRewardsList = rewardProgramsList !== null && rewardProgramsList.length > 1 && value.skipRewardsFilter === false

      navigate(showRewardsList ? "/credit-cards/select-card-rewards" : "/credit-cards/select-card-product", {
        state: {
          cardProvider: value,
          progress: progress + (showRewardsList ? 1 : 2),
        },
      })
    }
  }
  const didSelectRewardProgram = value => {
    DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_REWARDS_SELECTED, { id: value.id, ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })

    setRewardProgram(rewardProgram === null ? value : null)
    setProduct(null)

    navigate("/credit-cards/select-card-product", {
      state: {
        ...props.location.state,
        rewardProgram: value,
        progress: progress + 1,
      },
    })
  }
  const didSelectProduct = value => {
    DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_PRODUCT_SELECTED, { id: value.id, productCategory: "CREDIT_CARD", ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })

    setProduct(product === null ? value : null)

    if (store.cardId !== value.id) {
      store.cardUsage = {}
      store.customAnnualFees = null
      store.customPurchaseRate = null
      store.cardId = value.id
    }

    navigate("/credit-cards/select-card-repayment", {
      state: {
        ...props.location.state,
        product: value,
        progress: progress + 1,
      },
    })
  }

  const didSelectUsage = answerItemId => {
  //  console.log("didSelectUsage ", activeStep)
    const nextState = {
      ...props.location.state,
      activeStep: activeStep + 1,
      progress: progress + 1,
    }

    if (activeStep === 0) {
      DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_REPAYMENT_SELECTED, { id: answerItemId, ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })
      if (store.cardUsage.monthlyRepayment === 5) {
        store.cardUsage.monthlySpend = 1
        navigate("/credit-cards/select-card-balance", { state: { ...nextState, activeStep: activeStep + 2, progress: progress + 2 } })
      } else {
        if (store.cardUsage.monthlyRepayment === 5) store.cardUsage.monthlySpend = -1
        navigate("/credit-cards/select-card-spend", { state: nextState })
      }
    } else if (activeStep === 1) {
      DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_SPEND_SELECTED, { id: answerItemId, ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })
      navigate("/credit-cards/select-card-balance", { state: nextState })
    } else if (activeStep === 2) {
      DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_BALANCE_SELECTED, { id: answerItemId })
      addNewCard({ usage: store.cardUsage, product: product }, [])
    }
  }

  const handleSubmit = () => {
    // trackCustomEvent({
    //   category: window.location.pathname.split("/").join(" "),
    //   action: "Register",
    // })
    // add missing card
    let purpose
    if (addMissingCard) {
      purpose = "NEW_PRODUCT_SUBMITTED"
      API.submitAddMissingProduct(
        {
          surveyId: store.getState().survey.surveyId,
          purpose,
          name: firstName,
          lastName: lastName,
          email: email,
          providerName: cardProvider.name,
          rewardProgram: rewardProgram ? rewardProgram.value : "",
          productName: productName,
          purpose,
        },
        responseJson => {},
      )
      // add missing provider
    } else {
      purpose = "NEW_PROVIDER_SUBMITTED"
      API.submitAddMissingProduct(
        {
          surveyId: store.getState().survey.surveyId,
          purpose,
          name: firstName,
          lastName: lastName,
          email: email,
          providerName: providerName,
          rewardProgram: "",
          productName: productName,
          purpose,
        },
        responseJson => {},
      )
    }

    if (addMissingCard) {
      navigate("/credit-cards/select-card-product/missing-product-thank-you", {
        state: {
          addMissingCard: true,
          progress: 8,
        },
      })
    } else {
      navigate("/credit-cards/select-card-provider/missing-provider-thank-you", {
        state: {
          cardProvider: cardProvider,
          progress: 8,
        },
      })
    }
    // API.submitProductTransfer(
    //   {
    //     uid: store.getState().survey.uid,
    //     name,
    //     providerName: provider.name,
    //     fromProductName,
    //     fromProductId,
    //     toProductName,
    //     toProductId,
    //     email,
    //   },
    //   responseJson => {
    //     // store.dispatch(clearSurvey());
    //     // navigate("/credit-cards/thank-you", {
    //     //   state: { isRegistered: true },
    //     // })
    //   }
    // )
  }

  const onChangeEmail = e => {
    setEmail(e.target.value)
    setIsEmailValid(e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
  }

  let rewardProgramsList = null
  let productsList = props.location.state.productsList ? props.location.state.productsList : null
  if (cardProvider !== null) {
    if (cardProvider.skipRewardsFilter === false) {
      rewardProgramsList = rewardsListForProvider(cardProvider)

      if (rewardProgramsList.length === 1 && rewardProgramsList[0] !== rewardProgram) {
        setRewardProgram(rewardProgramsList[0])
      }

      if (rewardProgram !== null) {
        productsList = products.filter(item => item.provider === cardProvider.id && item.rewardProgram === rewardProgram.id).sort((a, b) => (a.id > b.id ? 1 : -1))
        // productsList.push(cardNotHere)
      }
      // //console.log("provider => " + JSON.stringify(provider));
      // //console.log("rewardProgramsList => " + rewardProgramsList);
    } else if (cardProvider.skipRewardsFilter === true) {
      productsList = products.filter(item => item.provider === cardProvider.id).sort((a, b) => (a.id > b.id ? 1 : -1))
      // productsList.push(cardNotHere)
    }
  }

  const isProductSelected = () => product !== null && !isUnknownCard()
  // productsList = products.filter((item) => item.provider === 400 && item.reward_program === 0).sort((a, b) => (a.id > b.id ? 1 : -1));
  // productsList.push({
  //   id: NOT_HERE_ID,
  //   name: "My card is not here",
  //   scheme: "",
  // });

  const addMissingProviderPage = () => {
    return (
      <div style={{ marginTop: "30px" }}>
        <Typography.P>Tell us about your credit card product so we can add it and help you save.</Typography.P>

        <form>
          <div style={{ marginTop: "40px", width: "100%" }}>
            <SGTextfield className={styles.tf} label="Provider name" onChange={e => setProviderName(e.target.value)} />
          </div>
          <div>
            <SGTextfield className={styles.tf} label="Card product name" onChange={onProductName} />
          </div>

          <Clickable.Text
            style={{ marginTop: "30px" }}
            comp={3}
            className="survey-button"
            disabled={!providerName || !productName}
            onClick={() => {
              DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_NEW_PROVIDER_SUBMITTED, { providerName, productName, ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })

              navigate("/credit-cards/select-card-provider/missing-provider-registration", {
                state: {
                  providerName: providerName,
                  productName: productName,
                  cardProvider: cardProvider,
                  progress: 7,
                },
              })
            }}
          >
            Submit
          </Clickable.Text>
        </form>
      </div>
    )
  }

  const addMissingCardPage = () => {
    return (
      <div className={styles.container1}>
        <Typography.P className={styles.message}>Let us know what credit card product you have so we can add it to help you save.</Typography.P>
        <div>
          <SGTextfield className={styles.tf} label="Card product name" onChange={onProductName} />
        </div>
        <Clickable.Text
          style={{ marginTop: "10px" }}
          comp={3}
          disabled={!productName}
          onClick={() => {
            DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_NEW_PRODUCT_SUBMITTED, { providerId: cardProvider.id, rewardProgramId: rewardProgram ? rewardProgram.id : null, productName, ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) })

            navigate("/credit-cards/select-card-product/missing-product-registration", {
              state: {
                addMissingCard: true,
                cardProvider: cardProvider,
                rewardProgram: rewardProgram,
                productName: productName,
                progress: 7,
              },
            })
          }}
          className="survey-button"
        >
          Submit
        </Clickable.Text>
      </div>
    )
  }

  const registerToSavePage = () => {
    return (
      <div>
        <Typography.P>We'll review your credit card details and look to add it to Stay or Go shortly.</Typography.P>
        <br />
        <Typography.P>Please register below so we can let you know once this is complete.</Typography.P>

        <form autoComplete="on">
          <div style={{ marginTop: "40px" }}>
            <SGTextfield className={styles.tf} id="outlined-basic" label="First Name" autoComplete="given-name" onChange={e => setFirstName(e.target.value)} />
          </div>
          <div>
            <SGTextfield className={styles.tf} id="outlined-basic" label="Last Name" autoComplete="family-name" onChange={e => setLastName(e.target.value)} />
          </div>
          <div>
            <SGTextfield className={styles.tf} id="outlined-basic" label="Email address" autoComplete="email" onChange={onChangeEmail} />
          </div>

          <Clickable.Text style={{ marginTop: "30px" }} comp={3} className="survey-button" onClick={handleSubmit} disabled={!firstName || !lastName || !isEmailValid}>
            Submit
          </Clickable.Text>
        </form>

        <Typography.P style={{ marginTop: "30px" }}>
          {"By submitting, you agree to the Stay or Go "}
          <a href="/privacy/" target="_blank" style={{ color: "#4f8ccc", textDecoration: "inherit" }}>
            Privacy Policy
          </a>
          {" and "}
          <a href="/terms/" target="_blank" style={{ color: "#4f8ccc", textDecoration: "inherit" }}>
            Terms of Use
          </a>
          {". This may include sharing the information you provide with your financial services provider and others to facilitate negotiation. We may contact you about products and offers made."}
        </Typography.P>
      </div>
    )
  }

  const thankYouPage = () => {
    return (
      <div style={{ marginTop: "80px" }}>
        <Typography.P>You will soon receive an email confirming your registration, and we'll notify you once our review of your credit card is complete.</Typography.P>
        <br />
        <Typography.P>If you have any questions, please contact Stay or Go by reply email.</Typography.P>

        <Clickable.Text style={{ marginTop: "80px" }} comp={3} className="survey-button" onClick={() => navigate("/")}>
          Home
        </Clickable.Text>
      </div>
    )
  }

  return (
    <div className="page-container">
      <div className="survey-container">
        <Typography.H3 comp="survey" className="survey-header">
          {isProductSelected() ? "Select your card usage" : progress === 7 ? "Register to save" : progress === 8 ? "Thank you" : "Select your credit card"}
        </Typography.H3>

        {progress < 7 ? <ProgressBar progress={progress} /> : null}

        {(product === null || isUnknownCard()) && progress < 3 && <ProvidersListView isSelectYourBanks={false} selectedProvider={cardProvider} list={providers} didSelectProviderCallback={didSelectCardProvider} />}
        {(product === null || isUnknownCard()) && rewardProgramsList !== null && rewardProgramsList.length > 1 && cardProvider.skipRewardsFilter === false && progress < 3 && <RewardProgramsListView list={rewardProgramsList} selected={rewardProgram} callback={didSelectRewardProgram} />}
        {cardProvider !== null && productsList !== null && !isUnknownCard() && !addMissingCard && <ProductsListView list={productsList} selected={product} callback={didSelectProduct} />}
        {isProductSelected() && <CardUsageViewController callback={didSelectUsage} activeStep={activeStep} />}
        {cardProvider !== null && cardProvider.id === 0 ? (progress === 0 ? addMissingProviderPage() : progress === 7 ? registerToSavePage() : progress === 8 ? thankYouPage() : null) : product === null && addMissingCard ? (progress === 2 ? addMissingCardPage() : progress === 7 ? registerToSavePage() : progress === 8 ? thankYouPage() : null) : null}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    rewardPrograms: state.main.rewardProgram,
    products: state.main.products,
    providers: state.main.provider,
    surveyId: state.survey.surveyId,
  }
}

export default connect(mapStateToProps)(AddCard)
